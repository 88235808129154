<template>
  <v-container class="fill-height" fluid="">
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <v-card class="elevation-0" max-width="436px">
          <v-row align="center" justify="center">
            <v-col align="center" justify="center">
              <v-img
                class="ma-3"
                style="max-width:145px"
                src="@/assets/square-logo.svg"
              ></v-img>
              <v-card-title class="justify-center">
                <h3>Reset password of Booking service</h3>
                <p>Введите email</p>
              </v-card-title>
              <v-card-text>
                <v-alert border="left" dark color="accent">
                  Введите почту, указанную при регистрации</v-alert
                >
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    id="email"
                    lazy-validation
                    color="secondary"
                    dense
                    outlined
                    label="Email"
                    :rules="rule.email"
                    v-model="form.email"
                    type="text"
                    hide-details="auto"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="px-4">
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      rounded
                      class="black--text"
                      color="primary"
                      depressed
                      large="large"
                      block
                      @click="sendInstructionToEmail"
                      :disabled="!valid"
                      data-cy="login"
                      >Выслать инструкции</v-btn
                    >
                  </v-col>
                  <v-col cols="12">
                    <router-link class="reset-link" :to="{ name: 'login' }"
                      >На страницу логина</router-link
                    ></v-col
                  >
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import dbg from "@/plugins/dbg";
import validation from "@/mixins/validationRules";
import requestControl from "@/mixins/requestControl";
import { AUTH_PASSWORD_RESET } from "../../../store/const/auth";
import { mapActions } from "vuex";
export default {
  mixins: [validation, requestControl],
  data() {
    return {
      form: {
        email: ""
      },
      serverErrors: {
        email: "",
        password: ""
      },
      viewPass: false,
      valid: true,
      isBlockButton: window.innerWidth >= 600,
      lazy: true,
      enterButtonLoading: false
    };
  },
  computed: {},
  methods: {
    ...mapActions("Auth", {
      passwordReset: AUTH_PASSWORD_RESET
    }),
    async sendInstructionToEmail() {
      const loginForm = this.$refs.form;
      const isValid = loginForm.validate();
      if (isValid) {
        await this.passwordReset(this.form);
        if (this.checkRequestOnError(AUTH_PASSWORD_RESET)) {
          this.$notify({
            type: "succ",
            text: "Письмо с инструкциями выслано на почтовый адрес"
          });
          // this.$router.push({ name: "reservation" });
        }
      } else {
        dbg("1error", loginForm);
      }
    }
  }
};
</script>
